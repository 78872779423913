<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-date-picker v-model="filterDaterange" type="daterange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" size="medium" style="width: 240px;margin-left:10px;" value-format="yyyy-MM-dd"
              @change="dateChange">
            </el-date-picker>
          </el-form>
        </div>
        <div class="action-right">
        </div>
      </div>
    </div>

    <el-table class="list-table" ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%;"
      :stripe="true" :border="false" v-loading="tableLoading">
      <el-table-column label="标题" prop="Title"></el-table-column>
      <el-table-column label="摘要" prop="ZhaiYao"></el-table-column>
      <el-table-column label="状态" prop="State">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.State == 0" effect="dark" size="small">未读</el-tag>
          <el-tag v-if="scope.row.State == 1" type="info" effect="dark" size="small">已读</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="110">
        <template slot-scope="scope">
          <el-button type="success" icon="el-icon-view" size="mini" @click="onShowDetail(scope.row)">查看详情</el-button>
          <!-- <el-button
            type="warning"
            icon="el-icon-error"
            size="mini"
            style="margin-left: 10px"
            @click="onListDel(scope.row)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <pagination background layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
      :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
      @current-change="onPageChange">
    </pagination>

    <el-dialog :title="activeDetailItem.Title" :visible.sync="dialogDetailVisible" width="600px"
      custom-class="geology-dialog" :modal-append-to-body="true">
      <div style="padding:10px 0;" v-html="activeDetailItem.Content">
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain type="primary" @click="dialogDetailVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getSysinfoList, updateSysinfoState, delSysinfo } from "@/api/system";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      filterDaterange: "",
      listfilter: {
        fname: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
      dialogDetailVisible: false,
      activeDetailItem: "",
    };
  },
  methods: {
    dateChange(value) {
      if (value) {
        var newdate = [];
        for (let index = 0; index < this.filterDaterange.length; index++) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }
        this.listfilter.fstarttime = newdate[0];
        this.listfilter.fendtime = newdate[1];
        this.getListData();
      } else {
        this.listfilter.fstarttime = "";
        this.listfilter.fendtime = "";
        this.getListData();
      }
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getSysinfoList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onShowDetail(item) {
      this.activeDetailItem = item;
      this.dialogDetailVisible = true;
      updateSysinfoState(item.Id).then(res => {
        if (res.code == 0) {
          item.State = 1;
        }
      })
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSysinfo(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => { });
    },
  },
  created() {
    this.getListData();
  },
};
</script>